@media (min-width: 48em) {
    .header__content {
        padding: 0 3rem;
    }
}

.header {
    background: #7fbcf0;
    /* width: 100%; */
    height: 80px;
    padding: 0 1.5rem;
    transition: 0.3s ease all;
}

.header__content {
    overflow: hidden;
    color: #fff;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
}

.header__content__logo {
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
}

.header__content__nav {
    top: 0;
    right: 100%;
    bottom: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(rgb(32, 32, 32), 0.9);
    backdrop-filter: blur(2px);
    transform: translate(0);
    transition: 0.3s ease transform;
}

.header__content__nav.isMenu {
    transform: translate(100%);
}

@media (min-width: 48em) {
    .header__content__nav {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
    }
}

.header__content__nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

@media (min-width: 48em) {
    .header__content__nav ul {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        margin-right: calc(0.5rem + 16px);
    }
}

.header__content__nav ul li:not(:last-child) {
    margin-bottom: 32px;
}

@media (min-width: 48em) {
    .header__content__nav ul li:not(:last-child) {
        margin-bottom: 0;
        margin-right: 16px;
    }
}

.header__content__nav ul li a {
    text-decoration: none;
    color: inherit;
    padding: 0.75rem 1.25rem;
    border-radius: 12px;
    transition: 0.3s ease all;
}

.header__content__nav ul li a:hover {
    background: rgba(#fff, 0.1);
}

.header__content__nav ul li a:active {
    border-radius: calc(12px + 6px);
    background: linear-gradient(rgba(#fff, 0.1), rgba(#fff, 0.2));
}

.header__content__nav .btn {
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: 12px;
    font-size: 1rem;
    font-family: inherit;
    background: rgb(162, 162, 246);
    color: rgb(32, 32, 32);
    border: 1px solid transparent;
    transition: 0.3s ease all;
    margin: 0.50em 0.50em;
}

.header__content__nav .btn:hover {
    border-color: rgb(162, 162, 246);
    background: rgba(rgb(162, 162, 246), 0.1);
    color: rgb(162, 162, 246);
}

.header__content__nav .btn:active {
    border-color: rgb(162, 162, 246);
    background: linear-gradient(rgba(rgb(162, 162, 246), 0.2), rgba(rgb(162, 162, 246), 0.3));
    color: rgb(162, 162, 246);
}

.header__content__nav .btn__login {
    background-color: rgb(32, 32, 32);
    color: #fff;
}

.header__content__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s ease all;
    position: relative;
}

.header__content__toggle:hover {
    color: rgb(162, 162, 246);
}

@media (min-width: 48em) {
    .header__content__toggle {
        display: none;
    }
}

.decor{
    text-decoration: none;
}